import React from 'react';
import { graphql } from 'gatsby';
import tw from 'twin.macro';
import { StaticImage } from 'gatsby-plugin-image';
import type { PageProps } from 'gatsby';

import Layout from '~components/Layout';
import SEO from '~components/SEO';
import Logo from '~components/Svg/Logo';
import Button from '~components/Button';
import { IconCard } from '~components/Card';
import CaptionImage from '~components/CaptionImage';
import Heading, { StyledHeading } from '~components/Heading';
import Text from '~components/Text';
import Values from '~components/Values';

import Access from '~components/Svg/Access';
import Bag from '~components/Svg/Bag';
import Check from '~components/Svg/Check';
import Directions from '~components/Svg/Directions';
import Globe from '~components/Svg/Globe';
import Pointer from '~components/Svg/Pointer';

import { VARIANT } from '../enums';

export const query = graphql`
  {
    insidePlan: file(relativePath: { eq: "inside-the-plan.pdf" }) {
      publicURL
    }
    reportCard: file(relativePath: { eq: "report-card-2016-2021.pdf" }) {
      publicURL
    }
    strategicPlan: file(relativePath: { eq: "strategic-plan-2016-2021.pdf" }) {
      publicURL
    }
  }
`;

const IndexPage: React.FC<PageProps> = ({ data }) => {
  const roundedImageStyle = tw`rounded-tr-4xl shadow -my-6 col-span-1 row-span-2`;
  const roundedImg = tw`rounded-tr-4xl`;

  return (
    <Layout>
      <SEO />
      <div tw="container">
        <div tw="grid grid-cols-3 gap-10 my-12">
          <StaticImage quality={85} src="../images/farm-stand.jpg" alt="" layout="fullWidth" />
          <StaticImage
            quality={85}
            src="../images/mohawk-building-night.jpg"
            alt=""
            layout="fullWidth"
          />
          <StaticImage
            style={roundedImg}
            quality={85}
            src="../images/students-with-maks.jpg"
            alt=""
            layout="fullWidth"
          />
        </div>
        <div tw="block px-3 mx-auto max-w-5xl my-12 text-gray-600">
          <Logo tw="w-full h-auto text-gray-600" />
        </div>
      </div>
      <div tw="bg-rose py-6 w-full">
        <div tw="container">
          <h1 tw="font-body text-white text-center tracking-widest text-xl sm:(text-3xl) lg:(text-5xl)">
            Strategic Plan 2022-2025
          </h1>
        </div>
      </div>
      <div tw="container">
        <div tw="grid grid-cols-3 gap-10 my-12">
          <StaticImage src="../images/students-working.jpg" alt="" layout="fullWidth" />
          <StaticImage
            src="../images/music-on-stage-no-water-bottle.jpg"
            alt=""
            layout="fullWidth"
          />
          <StaticImage src="../images/city-school-small.jpg" alt="" layout="fullWidth" />
        </div>
        <StyledHeading>Plan at a Glance</StyledHeading>
        <div tw="grid gap-10 md:(grid-cols-2) my-12">
          <Button variant="red" to="/intro">
            Introduction to the Plan
          </Button>
          <Button variant="red" to="/vision">
            Vision, Mission &amp; Values
          </Button>
          <Button variant="red" to="/aspirations/climate-change">
            Aspirations, Strategic Directions &amp; Leadership Outcomes
          </Button>
          <Button variant="red" to="/foundations">
            Strong Foundations
          </Button>
        </div>
        <StyledHeading>Introduction to the Plan</StyledHeading>
        <div tw="my-12 md:(grid my-20 grid-cols-5 grid-rows-2)">
          <StaticImage
            style={roundedImageStyle}
            src="../images/president.jpg"
            alt=""
            layout="fullWidth"
          />
          <div tw="bg-orange text-gray-800 py-8 px-12 items-center flex md:(col-span-4 rounded-tr-4xl)">
            <p tw="text-3xl font-bold">
              Our new strategic plan is attuned to these unusual and demanding times. It meets our
              short-term needs for recovery as a college and a community, and sets an innovative
              course for our future.
            </p>
          </div>
          <div tw="bg-red text-gray-50 py-8 px-12 justify-center flex flex-col space-y-6 md:(col-span-4)">
            <p tw="font-bold text-3xl">Letter from the President</p>
            <ul tw="list-inside list-disc text-white font-medium text-xl grid md:(grid-cols-2) gap-3">
              <li>A Compass Not a Roadmap</li>
              <li>Building on Our Strengths</li>
              <li>This Plan Belongs To Us All</li>
            </ul>
          </div>
        </div>
      </div>
      <div tw="bg-green mt-32 py-12">
        <div tw="container">
          <div tw="grid gap-6 items-center lg:(grid-cols-3)">
            <div tw="text-center lg:col-span-2">
              <Heading level="h2">Vision</Heading>
              <p tw="text-xl md:text-2xl">Future Ready. Learning for Life.</p>
            </div>
            <div tw="lg:(-my-16 col-span-1 text-right text-white)">
              <CaptionImage
                variant={VARIANT.ORANGE}
                caption="Pathfinder program focused on setting up students for success."
              >
                <StaticImage
                  style={roundedImg}
                  src="../images/guiding-a-student-2.jpg"
                  alt=""
                  layout="fullWidth"
                />
              </CaptionImage>
            </div>
          </div>
        </div>
      </div>
      <div tw="bg-red text-gray-50 mb-32 py-12">
        <div tw="container">
          <div tw="grid gap-10 items-center lg:(grid-cols-3)">
            <div tw="text-center lg:(col-span-2 order-2)">
              <Heading level="h2">Mission</Heading>
              <p tw="text-xl md:text-2xl">
                We educate and prepare highly skilled graduates for success and contribution to
                community, Canada and the world.
              </p>
            </div>
            <div tw="text-white lg:(-my-16 text-gray-800 order-1 col-span-1)">
              <CaptionImage variant={VARIANT.GREEN} caption="Students working on a plane.">
                <StaticImage
                  style={roundedImg}
                  src="../images/student-working-on-plane.jpg"
                  alt=""
                  layout="fullWidth"
                />
              </CaptionImage>
            </div>
          </div>
        </div>
      </div>
      <div tw="container">
        <StyledHeading>Values</StyledHeading>
        <Values />
        <StyledHeading>Aspirations</StyledHeading>
        <div tw="grid gap-10 my-12 md:(grid-cols-2) lg:(grid-cols-3)">
          <IconCard
            variant={VARIANT.ROSE}
            to="/aspirations/truth-reconciliation"
            Icon={<Directions tw="w-12" />}
          >
            Meaningfully advance Truth and Reconciliation
          </IconCard>
          <IconCard variant={VARIANT.RED} to="/aspirations/future-ready" Icon={<Check tw="w-12" />}>
            Enable Future Ready education, research, programs, services and experiences – for
            students, employees and our community
          </IconCard>

          <IconCard
            variant={VARIANT.ROSE}
            to="/aspirations/celebrate-every-person"
            Icon={<Access tw="w-12" />}
          >
            Be a place that honours, values and celebrates the whole of every person
          </IconCard>

          <IconCard variant={VARIANT.RED} to="/aspirations/education" Icon={<Pointer tw="w-12" />}>
            Ensure access to education and reduce barriers for all
          </IconCard>
          <IconCard
            variant={VARIANT.ROSE}
            to="/aspirations/workforce-development"
            Icon={<Bag tw="w-12" />}
          >
            Lead in workforce development
          </IconCard>
          <IconCard
            variant={VARIANT.RED}
            to="/aspirations/climate-change"
            Icon={<Globe tw="w-12" />}
          >
            Make a measurable impact on climate change
          </IconCard>
        </div>
        <figure tw="my-12 flex flex-col justify-between space-y-4">
          <div tw="relative">
            <StaticImage
              style={roundedImg}
              src="../images/mohawk-building-larger.jpg"
              alt=""
              layout="fullWidth"
            />
            <div tw="absolute bg-green text-gray-800 left-6 bottom-0 p-6 rounded-tr-4xl max-w-3xl">
              <StyledHeading>Strong Foundations</StyledHeading>
              <Text tw="font-bold">
                People | Planning | Reputation | Technology | Financial Health
              </Text>
              <Text>
                Strong Foundations underpin our new Strategic Plan to ensure that we can progress
                toward our aspirations and ultimately achieve our leadership outcomes. We will
                build on the expertise of our faculty and staff, maintain ongoing planning for
                long-term success, enhance and leverage our positive reputation, and remain focused
                on innovative technology. Above all, we will continue to pay diligent attention to
                our overall financial health.
              </Text>
            </div>
          </div>
          <div tw="block w-full h-6 shadow bg-orange" />
        </figure>
        <StyledHeading>From Recovery to Innovation</StyledHeading>
        <div tw="grid gap-10 md:(grid-cols-3) my-12">
          <Button variant="red" to={data.strategicPlan.publicURL}>
            Strategic Plan 2016-2021 (PDF)
          </Button>
          <Button variant="red" to={data.reportCard.publicURL}>
            Report Card on 2016-2021 (PDF)
          </Button>
          <Button variant="red" to={data.insidePlan.publicURL}>
            How We Developed the Plan
          </Button>
        </div>
      </div>
      <div tw="w-full mt-24">
        <StaticImage src="../images/footer.jpg" alt="" layout="fullWidth" />
      </div>
    </Layout>
  );
};

export default IndexPage;
