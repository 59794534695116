import React from 'react';
import tw from 'twin.macro';
import { StaticImage } from 'gatsby-plugin-image';

import Heading from './Heading';
import Text from './Text';

const Values = () => {
  const imgStyle = tw`absolute inset-0 bg-cover bg-center`;
  return (
    <div tw="flex flex-col space-y-10 my-12">
      <div tw="grid bg-rose text-white rounded-tr-4xl shadow-xl lg:(grid-cols-4)">
        <div tw="col-span-1 relative">
          <StaticImage style={imgStyle} src="../images/we-love-our-students.jpg" alt="" />
        </div>
        <div tw="col-span-3 px-10 py-4">
          <Heading level="h3">We are student focused.</Heading>
          <Text>Students and learning are at the heart of all we do.</Text>
        </div>
      </div>
      <div tw="grid bg-orange text-gray-800 shadow-xl lg:(grid-cols-4)">
        <div tw="col-span-3 px-10 py-4">
          <Heading level="h3">We are committed to excellence.</Heading>
          <Text>
            We are committed to innovation in education, corporate services and student services.
          </Text>
        </div>
        <div tw="col-span-1 relative">
          <StaticImage style={imgStyle} src="../images/student-adjusting-water-bag.jpg" alt="" />
        </div>
      </div>
      <div tw="grid bg-red text-white shadow-xl lg:(grid-cols-4)">
        <div tw="col-span-1 relative">
          <StaticImage style={imgStyle} src="../images/students-outside-building.jpg" alt="" />
        </div>
        <div tw="col-span-3 px-10 py-4">
          <Heading level="h3">We engage our community.</Heading>
          <Text>We are responsive to and collaborate with the communities we serve.</Text>
        </div>
      </div>
      <div tw="grid bg-green text-gray-800 shadow-xl lg:(grid-cols-4)">
        <div tw="col-span-3 px-10 py-4">
          <Heading level="h3">We are inclusive.</Heading>
          <Text>We ensure a welcoming and supportive environment for all.</Text>
        </div>
        <div tw="col-span-1 relative">
          <StaticImage style={imgStyle} src="../images/stduents-4-auditorium.jpg" alt="" />
        </div>
      </div>
      <div tw="grid bg-rose text-white shadow-xl lg:(grid-cols-4)">
        <div tw="col-span-1 relative">
          <StaticImage style={imgStyle} src="../images/students-in-an-audience.jpg" alt="" />
        </div>
        <div tw="col-span-3 px-10 py-4">
          <Heading level="h3">We are accountable.</Heading>
          <Text>We act with integrity, transparency, and base our decisions on evidence.</Text>
        </div>
      </div>
    </div>
  );
};

export default Values;
