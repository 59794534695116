import React from 'react';
import tw from 'twin.macro';

import type { TwStyle } from 'twin.macro';

import { VARIANT } from '../../enums';

interface CaptionImage {
  variant: VARIANT;
  caption: string;
}

const CaptionImage: React.FC<CaptionImage> = ({ variant, caption, children }) => {
  const baseStyles = [tw`block w-full h-6 shadow`];

  let colourStyles: TwStyle[] = [];
  switch (variant) {
    case 'green':
      colourStyles = [tw`bg-green`];
      break;
    case 'blue':
      colourStyles = [tw`bg-blue-700`];
      break;
    case 'rose':
      colourStyles = [tw`bg-rose`];
      break;
    case 'red':
      colourStyles = [tw`bg-red`];
      break;
    case 'orange':
      colourStyles = [tw`bg-orange`];
      break;
    case 'yellow':
      colourStyles = [tw`bg-yellow-200`];
      break;
  }

  return (
    <figure tw="flex flex-col justify-between space-y-4">
      {children}
      <div css={[...baseStyles, ...colourStyles]} />
      {/* <figcaption tw="font-bold">{caption}</figcaption> */}
    </figure>
  );
};

export default CaptionImage;
